<template>
  <FooterNav />
</template>

<script>
import FooterNav from './FooterNav.vue'
export default {
  name: 'footer',
  components: {
    FooterNav
  },
  props: {
  }
}
</script>

<style scoped>
</style>
